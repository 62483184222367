import { Autorenew, Favorite, X } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import React from "react";
import { parseTwitterHashtag, parseTwitterUsername, parseURL } from "../../../common";
import PostDateElement from "../element/PostDateElement";
import PostMetaElement from "../element/PostMetaElement";
import PostTextElement from "../element/PostTextElement";
import PostTitleElement from "../element/PostTitleElement";

function TwitterPostCard({post, padding}){
    
    let text = post.post_text;
    text = parseURL(text);
    text = parseTwitterUsername(text);
    text = parseTwitterHashtag(text);
    
    const title = "@" + post.user_screenname;
    return <>
        <Box p={padding}>
            <PostTitleElement title={title} link={"https://x.com/" + post.user_screenname} />
        </Box>
        {post.img && <img alt={title} src={post.img} style={{maxWidth: '100%'}}/>}
        <Box p={padding}>
            <PostTextElement post={post} text={text} />

            <PostDateElement post={post} />
        </Box>
        <Divider />
        <Box p={padding} display="flex" alignItems={"center"} justifyContent="space-between">
            <Box display="flex" alignItems={"center"} mr={1}>
                <Favorite sx={{color: '#CC0000', marginRight: 0.5}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.tw_likes}</span>

                <Autorenew sx={{ fontSize: 12, marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.tw_rts}</span>
            </Box>
            <X sx={{color: '#000000'}}/>
        </Box>
        <Divider />
        <Box p={padding}>
            <PostMetaElement post={post} />
        </Box>
    </>
}
export default TwitterPostCard;