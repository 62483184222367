import { Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import JobPlatformSelect from "../../common/JobPlatformSelect";
import PostFieldSelect from "../../common/PostFieldSelect";
import HelpAdornment from "../../common/HelpAdornment";

function SourceForm({form, onChange, errors}){
    const sourceForm = form || {};
    const setSourceForm = f => {
        onChange && onChange(f);
    }

    return <Grid container spacing={4} columnSpacing={3}>
        <Grid item xs={12}>
            <TextField
                fullWidth
                size="small"
                required
                autoFocus
                error={!!errors.name}
                label="Source name"
                value={sourceForm.name || ""}
                onChange={e => setSourceForm({...sourceForm, name: e.target.value})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"sourceName"} />
                }}

            />
        </Grid>
        <Grid item xs={6}>
            <JobPlatformSelect
                required
                value={form.platform || ""}
                onChange={platform => setSourceForm({...sourceForm, platform})}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
                type="numeric"
                size="small"
                fullWidth
                label="Audience"
                value={form.audience || ""}
                helperText="Leave blank to automatic calculate X audience"
                onChange={e => setSourceForm({...sourceForm, audience: e.target.value})}
            />
        </Grid>
        <Grid item xs={6}>
            <PostFieldSelect
                fullWidth
                required
                label="Match field"
                value={sourceForm.matchName || ""}
                onChange={matchName => setSourceForm({...sourceForm, matchName})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"sourceMatchName"} />
                }}

            />
        </Grid>
        <Grid item xs={6}>
            <TextField
                fullWidth
                size="small"
                required
                error={!!errors.matchvalue}
                label="Match value"
                value={sourceForm.matchValue || ""}
                onChange={e => setSourceForm({...sourceForm, matchValue: e.target.value})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"sourceMatchValue"} />
                }}

            />
        </Grid>
    </Grid>
}

export default SourceForm;