import { Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import BooleanSelect from "../common/BooleanSelect";
import HelpAdornment from "../common/HelpAdornment";
import JobFrequencySelect from "../common/JobFrequencySelect";
import JobPlatformSelect from "../common/JobPlatformSelect";
import LanguageSelect from "../common/LanguageSelect";
import ProjectSelect from "../common/ProjectSelect.js.js";

function JobForm({form, onChange, errors, hideAdvancedFields=false}){
    const jobForm = form || {};
    const project = useSelector(state => state.project.single);
    const setJobForm = f => {
        onChange && onChange(f);
    }
    const searchHelpText = {
        // 'tw': "boolean operators search (AND, OR allowed); special operators from: to:",
        'web': "boolean operators search (AND, OR allowed)",
        'in': "boolean operators search (AND, OR allowed)",
        'inm': "one Linkedin username, internal ID or URN"
    }
    searchHelpText.wn = searchHelpText.wb = searchHelpText.wf = searchHelpText.web;

    const requiredPlatformFields = {
        nf: ['all'],
        ig: ['all'],
        nftw: ['all'],
        tt: ['all'],
        nc: ['authors'],
        ic: ['authors'],
        nftc: ['authors'],
        tc: ['authors'],
    }
    const platformFields = {
        nc: {
            authors: "Results are from any of these pages (FB page username or userid, comma separated)",
            excluded_authors: "Results are NOT from any of these pages (FB page username or userid, space separated)"
        },
        ic: {
            authors: "Results are from any of these account (Instagram username, comma separated)",
            excluded_authors: "Results are NOT from any of these account (Instagram username, space separated)"
        },
        nftc: {
            authors: "Results are from any of these account (X username, comma separated)",
            excluded_authors: "Results are NOT from any of these account (X username, space separated)"
        },
        tc: {
            authors: "Results are from any of these account (TikTok username, comma separated)",
            excluded_authors: "Results are NOT from any of these account (TikTok username, space separated)"
        },
        nf: {
            all: "Results must contain all these terms (space separated, phrases in quotes)",
            one: "Results may contain one or more of these terms (space separated, phrases in quotes)",
            none: "Results don't contain any of these terms (space separated, phrases in quotes)",
            hashtags: "Results must contain all of these hashtags (space separated, omit the '#')",
            authors: "Results are from any of these pages (FB page username or userid, comma separated)",
            excluded_authors: "Results are NOT from any of these pages (FB page username or userid, space separated)"
        },
        ig: {
            all: "Results must contain all these terms (space separated, phrases in quotes)",
            one: "Results may contain one or more of these terms (space separated, phrases in quotes)",
            none: "Results don't contain any of these terms (space separated, phrases in quotes)",
            hashtags: "Results must contain all of these hashtags (space separated, omit the '#')",
            authors: "Results are from any of these account (Instagram username, comma separated)",
            excluded_authors: "Results are NOT from any of these account (Instagram username, space separated)"
        },
        nftw: {
            all: "Results must contain all these terms (space separated, phrases in quotes)",
            one: "Results may contain one or more of these terms (space separated, phrases in quotes)",
            none: "Results don't contain any of these terms (space separated, phrases in quotes)",
            hashtags: "Results must contain all of these hashtags (space separated, omit the '#')",
            authors: "Results are from any of these account (X username, comma separated)",
            excluded_authors: "Results are NOT from any of these account (X username, space separated)"
        },
        tt: {
            all: "Results must contain all these terms (space separated, phrases in quotes)",
            one: "Results may contain one or more of these terms (space separated, phrases in quotes)",
            none: "Results don't contain any of these terms (space separated, phrases in quotes)",
            hashtags: "Results must contain all of these hashtags (space separated, omit the '#')",
            authors: "Results are from any of these account (TikTok username, comma separated)",
            excluded_authors: "Results are NOT from any of these account (TikTok username, space separated)"
        },
    }
    

    return <Grid container spacing={4} columnSpacing={6}>
        <Grid item xs={12}>
            <TextField
                fullWidth
                required
                autoFocus
                error={!!errors.label}
                variant="standard"
                label="Job name"
                helperText={"The name you're identifying the job with"}
                value={jobForm.label || ""}
                onChange={e => setJobForm({...jobForm, label: e.target.value})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"jobLabel"} />
                }}

            />
        </Grid>
        
        <Grid item xs={6}>
            <JobPlatformSelect
                error={!!errors.type}
                helperText={"The platform to search"}
                value={jobForm.type}
                onChange={type => setJobForm({...jobForm, type})}

            />
        </Grid>
        <Grid item xs={6}>
            <JobFrequencySelect
                error={!!errors.freq}
                helperText={"How frequently the job must run"}
                required
                value={jobForm.freq}
                onChange={freq => setJobForm({...jobForm, freq})}

            />
        </Grid>
        {jobForm.type && !platformFields[jobForm.type] && <Grid item xs={12}>
            <TextField
                fullWidth
                required
                error={!!errors.search}
                variant="standard"
                label="Search"
                helperText={searchHelpText[jobForm.type] || "The search string for your job"}
                value={jobForm.search || ""}
                onChange={e => setJobForm({...jobForm, search: e.target.value})}
                InputProps={{
                    endAdornment: <HelpAdornment helpKey={"jobSearch"} />
                }}
            />
        </Grid> }
        {jobForm.type && platformFields[jobForm.type] && <>
            {Object.keys(platformFields[jobForm.type]).map(key => {
                const label = platformFields[jobForm.type][key];
                const requiredArray = requiredPlatformFields[jobForm.type];
                const required = requiredArray.indexOf(key)!==-1;
                return <Grid sm={12} key={key} item>
                    <TextField
                        fullWidth
                        required={required}
                        // error={!!errors.search}
                        variant="standard"
                        label={label}
                        value={(jobForm.searchJson || {})[key] || ""}
                        onChange={e => setJobForm({...jobForm, searchJson: {...jobForm.searchJson, [key]: e.target.value}})}
                    />  
                </Grid>
            })}
        </>}

        <Grid item xs={6}>
            {/* hide language if Linkedin search or member */}
            {jobForm.type !== 'in' && jobForm.type !== 'inm' ? (
            <LanguageSelect
                error={!!errors.searchLang}
                // required
                helpKey="jobLanguage"
                value={jobForm.searchLang}
                onChange={searchLang => setJobForm({...jobForm, searchLang})}
            />
            ) : null}
        </Grid>
        {!hideAdvancedFields && <>
            <Grid item xs={6}>
                <BooleanSelect
                    required
                    label="Active"
                    helperText={"Activate the job"}
                    value={jobForm.active || false}
                    onChange={active => setJobForm({...jobForm, active})}

                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    error={!!errors.notes}
                    variant="standard"
                    label="Job notes"
                    helperText={""}
                    value={jobForm.notes || ""}
                    onChange={e => setJobForm({...jobForm, notes: e.target.value})}
                    InputProps={{
                        endAdornment: <HelpAdornment helpKey={"jobNotes"} />
                    }}

                />
            </Grid>
            {!project && <Grid item xs={12}>
                <ProjectSelect
                    fullWidth
                    error={!!errors.projects}
                    variant="standard"
                    label="Projects"
                    helperText={""}
                    value={jobForm.projects || []}
                    onChange={projects => setJobForm({...jobForm, projects})}
                />
            </Grid> }
        </>}
    </Grid>
}

export default JobForm;